{
  "COMPANIES": {
    "NAME": "Nome",
    "TAXPAYER": "CPF/CNPJ",
    "PHONE_NOTIFICATION": "Telefone p/ notificação",
    "DOCUMENTS_ON_PLAN": "QTD. Documentos no Plano",
    "PRICE_ADDITIONAL_DOCUMENT": "Preço adicional documento",
    "PRICE_PLAN": "Preço do plano",
    "TOTAL_DOCUMENTS_ALL_COMPANIES": "Total de documentos",
    "SALE_NAME": "Nome da Venda",
    "INDEX": "Companhias"
  },
  "DOCUMENTS": {
    "DOCUMENTS_CREATED_AT": "Documentos Criados em",
    "DOCUMENTS_FINISHED_AT": "Documentos Finalizados em",
    "SYSTEM": "Sistema",
    "DATE_OF_ASSYGN": "Data da Assinatura",
    "DOCUMENT_NAME": "Nome do Documento",
    "STATUS": "Status",
    "PENDING_BY": "Pendentes Por",
    "EMAIL_SIGNER": "E-mail do Signatário",
    "DATE_EXPIRATION_INITIAL": "Data de Expiração inicial",
    "DATE_EXPIRATION_FINAL": "Data de Expiração final",
    "SIGNER": "Signatário",
    "REALLY_NOTIFY_PENDING": "Tem certeza que deseja enviar solicitação de assinatura para todos os usuários pendentes",
    "NOTIFY_PENDING": "Notificar Pendentes",
    "DOCUMENTS": "Documentos",
    "PHONE": "Telefone",
    "NAME": "Nome",
    "CANCEL": "Cancelar Documento",
    "STATS_DOCUMENT": "Status do Documento",
    "SIGNATURE_STATS": "Status da Assinatura",
    "IN_PROGRESS": "Em Progresso",
    "THIS_MOMENT": "Neste Momento",
    "CANCELEDS": "Cancelados",
    "FINISHEDS": "Assinados",
    "SIGNERS": "SIGNATÁRIOS",
    "DEADLINE": "Expiração",
    "ALL": "Ver Todos",
    "VIEW_DOC_ORIGINAL": "Ver Original",
    "VIEW_DOC_ALREADY_SIGN": "Ver Assinado",
    "MSG": {
      "CLICK_TO_VIEW_ALL_SIGNERS": "Clique em visualizar para ver todos os signatários",
      "NONE_DOCUMENT_FOUND": "Nenhum documento encontrado!",
      "ARE_SURE_WANT_TO_DELETE_THIS_DOCUMENT": "Tem certeza que deseja excluir este documento?",
      "ARE_SURE_WANT_TO_SEND_WHATSAPP_MESSAGE": "Tem certeza que deseja enviar solicitação de assinatura via whatsapp para esse usuário?"
    },
    "MODAL_TITLE": {
      "DOCUMENT": "Documento"
    },
    "MENU": {
      "WAITING": "Aguardando",
      "PENDING_ADM": "Pendentes P/ Adm",
      "HOME": "Home",
      "GENERAL": "Geral",
      "FINISHED": "Assinado",
      "SIGNEDS": "Assinados",
      "CANCELED": "Cancelados",
      "ALL": "Todos os Documentos",
      "EXPIRED": "Expirados"
    },
    "MESSAGES": {
      "SUCCESS_CANCEL_DOCUMENT": "Documento cancelado com sucesso!",
      "FAIL_CANCEL_DOCUMENT": "Documento não deletado!"
    }
  },
  "APP": {
    "PROFILE": "Perfil",
    "SINGOUT": "Sair",
    "CHANGE_PASSWORD": "Alterar Senha"
  },
  "HOME": {
    "SESSION_IS_EXPIRED_DID_YOU_RENOVE": "A sessão será expirada nos proximos 5 minutos, deseja renovar?",
    "DOCUMENT": "Documentos",
    "NOW_MOMENT": "Neste Momento",
    "RUNNING": "Aguardando",
    "PENDING_ADM": "Pendente P/ Administração",
    "ASSINED": "Assinados",
    "EXPIRED": "Expirados",
    "CANCELED": "Cancelados",
    "VIEW_ALL_DOC": "Todos os Documentos",
    "LAST_30_DAYS": "Últimos 30 Dias",
    "RESUM_ASSIGN": "Resumo de Assinaturas",
    "LAST_12_MONTHS": "Últimos 12 Meses"
  },
  "ACTIONS": {
    "TRASH": "Limpar",
    "FILTER": "Filtrar",
    "EDIT": "Editar",
    "CLOSE": "Fechar",
    "SAVE": "Salvar",
    "VIEW": "Visualizar"
  },
  "EXPORTS": {
    "XLSX": "Exportar em XLSX"
  }
}
