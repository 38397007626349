import {createWebHistory, createRouter} from "vue-router";
import beforeEach from "./beforeEach";

const routes = [
    {
        path: "/",
        name: "App",
        component: () => import(/* webpackChunkName: "LayoutApp" */ '@/layouts/App'),
        children: [
            {
                path: "/",
                name: "Home",
                component: () => import(/* webpackChunkName: "Home" */ '@/pages/home/homeComponent'),
            },
            {
                path: "/login/:token",
                name: "Login",
                component: () => import(/* webpackChunkName: "Login" */ '@/pages/home/loginComponent'),
            },
            {
                path: "/loginDisponibilidade/:token",
                name: "loginDisponibilidade",
                component: () => import(/* webpackChunkName: "Login" */ '@/pages/home/loginComponent'),
            },
            {
                path: "/all",
                name: "All",
                component: () => import(/* webpackChunkName: "All" */ '@/pages/documents/indexDocumentsComponent'),
            },
            {
                path: "/running",
                name: "Running",
                component: () => import(/* webpackChunkName: "Running" */ '@/pages/documents/indexDocumentsComponent'),
            },
            {
                path: "/canceled",
                name: "Canceled",
                component: () => import(/* webpackChunkName: "Canceled" */ '@/pages/documents/indexDocumentsComponent'),
            },
            {
                path: "/pendentes-adm",
                name: "PendingAdm",
                component: () => import(/* webpackChunkName: "PendingAdm" */ '@/pages/documents/indexDocumentsComponent'),
            },
            {
                path: "/expired",
                name: "Expired",
                component: () => import(/* webpackChunkName: "Expired" */ '@/pages/documents/indexDocumentsComponent'),
            },
            {
                path: "/companhias",
                name: "CompanyIndex",
                component: () => import(/* webpackChunkName: "CompanyIndex" */ '@/pages/company/companyIndex'),
            },
            {
                path: "/signed",
                name: "Signed",
                component: () => import(/* webpackChunkName: "Signed" */ '@/pages/documents/indexDocumentsComponent'),
            },
        ],
    },
    {
        path: "/inautorizado",
        name: "Unauthorized",
        component: () => import(/* webpackChunkName: "Unauthorized" */ '@/pages/home/UnauthorizedIndexComponent'),
    },
    {
        path: '/:catchAll(.*)',
        name: "NotFound",
        component: () => import(/* webpackChunkName: "NotFound" */ '@/pages/home/UnauthorizedIndexComponent'),
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(beforeEach);

export default router;
