import {http} from '../../../services/config';
import errorMsg from '../../../locales/errorMsg';
import Toast from '../../../Helpers/Toast';

export default {
    indexCompany({commit}, {page = 1,perpage = null, search = null}){
        http.get(`v1/companhias?page=${page}`, {params:{perpage, search}}).then(resp => {
            commit('setIndexCompany', resp.data);
            commit('changeLoading', false);
        }).catch(() => {
            commit('setIndexCompany', {});
            commit('changeLoading', false);
        });
    },
    showCompany({commit}, {id}){
        http.get(`v1/companhias/${id}`).then(resp => {
            commit('setShowCompany', resp.data);
            commit('changeLoading', false);
        }).catch(() => {
            commit('setShowCompany', {});
            commit('changeLoading', false);
        });
    },
    createCompany({commit, dispatch}, {data}){
        http.post(`v1/companhias`, data).then(() => {
            document.getElementById('closeX')?.click();
            commit('setShowCompany', {});
            dispatch('indexCompany',{});
            Toast.success(`Registro Criado`);
        }).catch((err) => {
            commit('changeLoading', false);
            errorMsg.errorMsg(err);
        });
    },
    editCompany({commit, dispatch}, {data}){
        http.put(`v1/companhias/${data.id}`, data).then(() => {
            document.getElementById('closeX')?.click();
            commit('setShowCompany', {});
            dispatch('indexCompany',{});
            Toast.success(`Registro Atualizado`);
        }).catch((err) => {
            commit('changeLoading', false);
            errorMsg.errorMsg(err);
        });
    }
}