import Toast from '../../../Helpers/Toast';
import {http} from '../../../services/config';

export default {
    getIndexDocument({commit, dispatch}, {filter = '', page = 1, filters = {}, perpage = null}) {
        commit('changeLoading', true);
        if (filter === 'all') {
            if (filters.status && filters.status !== 'all') {
                filter = filters.status;
            } else {
                filter = null
            }
        }
        if (filter === 'pendingadm' || filters.pendingBy) {
            dispatch('getPendingAdm', {page, filters, perpage});
            return;
        }
        http.get('v1/documentos', {params: {filter: filter, page: page, filters, perpage}}).then(resp => {
            commit('setIndexDocument', resp.data);
            commit('changeLoading', false);
        }).catch(() => {
            commit('setIndexDocument', {});
            commit('changeLoading', false);
        });
    },
    getPendingAdm({commit}, {page = 1, term = null, filters = {}, perpage}) {
        commit('changeLoading', true);
        http.get(`v1/documentos/pending-sign?pendingBy=${filters?.pendingBy ? filters.pendingBy : 'seller,witness'}${filters?.pendingBy?'':'&notPendingBy=buyer,sign,broker'}`, {
            params: {
                page: page,
                term: term,
                filters,
                perpage,
            }
        }).then(resp => {
            commit('setIndexDocument', resp.data);
            commit('changeLoading', false);
        }).catch(() => {
            commit('setIndexDocument', {});
            commit('changeLoading', false);
        });
    },
    showDocument({commit}, document) {
        commit('changeLoading', true);
        http.get('v1/documentos/' + document.id).then(resp => {
            commit('changeLoading', false);
            if (resp.data.validations) return;
            window.open(resp.data.original_file_url, '_blank');
        }).catch(() => {
            commit('changeLoading', false);
        });
    },
    showDocumentSigned({commit}, document) {
        commit('changeLoading', true);
        http.get('v1/documentos/' + document.id).then(resp => {
            commit('changeLoading', false);
            if (resp.data.validations) return;
            window.open(resp.data.signed_file_url, '_blank');
        }).catch(() => {
            commit('changeLoading', false);
        });
    },
    async sendWhatsappMessage({commit}, {whatsapp, showLoading = true}) {
        showLoading && commit('changeLoading', true);
        if (!whatsapp?.company_name) whatsapp.company_name = localStorage.getItem('company_name');

        await http.post('v1/signatarios/whatsapp', whatsapp).then(() => {
            Toast.success(`Notificação Enviada: ${whatsapp?.name ? whatsapp.name : ''}`);
            showLoading && commit('changeLoading', false);
        }).catch((err) => {
            let motiv = err?.response?.data?.message ? `; Motivo: ${err.response.data.message}` : '';
            Toast.error(`Falha ao Notificar: ${whatsapp?.name ? whatsapp.name : ''} ${motiv}`);
            showLoading && commit('changeLoading', false);
        });
    },
    actionCancelDocument({commit}, {id, accessToken}) {
        commit('changeLoading', true);
        http.patch('v1/documentos/cancelar/' + id, [], {
            headers: {'access-token': [accessToken]}
        }).then((resp) => {
            if (!(resp.data.validations)) {
                Toast.success('DOCUMENTS.MESSAGES.SUCCESS_CANCEL_DOCUMENT');
            }
            commit('reloadIndex', true);
        }).catch((err) => {
            commit('changeLoading', false);
            if (err?.response?.data?.validations?.errors?.length)
                Toast.error(err?.response?.data?.validations?.errors[0]);
        });
    }
}