import Auth from '@/services/auth';
import {http} from "../services/config";
import store from '../vuex';

export default async (to, from, next) => {
    try {
        if (to.name === 'Login') {
            const tokenInitial = to.params.token
            await Auth.getAuthorization(tokenInitial).then(async (resp) => {
                localStorage.removeItem('access_token_security');
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('company_id');
                localStorage.removeItem('expire_at');
                localStorage.setItem('access_token_security', resp.data.access_token_security);
                localStorage.setItem('refresh_token', resp.data.refresh_token_security);
                localStorage.setItem('company_id', resp.data.database);
                localStorage.setItem('user_name', resp.data.userName);
                localStorage.setItem('mail', resp.data.mail);
                localStorage.setItem('company_name', resp.data.companyName);
                localStorage.setItem('description_company', resp.data.description);
                localStorage.setItem('access_token', resp.data.access_token);
                localStorage.setItem('expire_at', resp.data.expire_at);
            }).catch(() => {
                store.dispatch('actionSignOut');
                return;
            });
            next({name: 'Home'});
        } else if (to.name === 'loginDisponibilidade') {
            let data = JSON.parse(atob(to.params.token))
            localStorage.setItem('company_id', data.database);
            localStorage.setItem('user_name', data.userName);
            localStorage.setItem('mail', data.mail);
            localStorage.setItem('company_name', data.companyName);
            localStorage.setItem('description_company', data.description);
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('expire_at', data.expire_at);
            if (!localStorage.getItem('access_token') || !localStorage.getItem('company_id') || !localStorage.getItem('expire_at'))
            {
                store.dispatch('actionSignOut');
                return;
            }
            next({name: 'Home'});
        }
        else if (to.name === 'Unauthorized') {
            next();
        } else {
            if (!localStorage.getItem('access_token') || !localStorage.getItem('company_id') || !localStorage.getItem('expire_at'))
            {
                store.dispatch('actionSignOut');
                return;
            }
            http.defaults.headers.Authorization = 'Bearer ' + localStorage.getItem('access_token');
            http.defaults.headers.common['company-id'] = localStorage.getItem('company_id');
            next();
        }
    } catch (err) {
        store.dispatch('actionSignOut');
    }
}




