import {useToast} from "vue-toastification";

function toastPop(msg, type = 'error') {
    const toast = useToast();
    switch (type) {
        case 'info':
            toast.info(msg, {timeout: 3000});
            break;
        default:
            toast.error(msg, {timeout: false});
    }
}

export default {
    errorMsg(error, type = 'error') {
        const erro = error.response.data?.message;

        if (erro) {
            toastPop(erro, type);
        } else {
            const erro = error.response.data?.validations.errors;

            if (erro.sql || !erro || erro.length === 0) {
                toastPop(error.response.data.validations.message, type);
            } else if (erro.msg) {
                if (Array.isArray(erro.msg)) {
                    erro.msg.forEach(msg => toastPop(msg, type));
                } else {
                    const msg = erro.msg?.msg;
                    msg ? toastPop(msg, type) : toastPop(erro.msg, type);
                }
            } else {
                for (let column in erro) {
                    for (let msg in erro[column]) {
                        toastPop(erro[column][msg], type);
                    }
                }
            }
        }
    },
}