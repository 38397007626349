import {http} from "./config";

export default {
    getAuthorization: (token) => {
        return http.get('v1/autenticacao', {headers: {'Authorization': token}});
    },
    checkToken: () => {
        return http.get('v1/autenticacao/checkToken');
    },
    renewToken: () => {
        return http.post('v1/renew-token');
    },
    tenentExists: (companyId) => {
        return http.get(`v1/companhias/tenant-existe-companhia/${companyId}`);
    }
}
